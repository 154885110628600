import React from "react";
import ReactDOM from "react-dom";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import ModalWrapper from "./ui/modals/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import NativeAdOverlay from "./ui/components/NativeAdOverlay";
import "./ui/styles/app.scss";
import IndexPage from "./ui/pages/IndexPage";
import UploadPage from "./ui/pages/UploadPage";
import ProcessingPage from "./ui/pages/ProcessingPage";
import ProcessingTelegramPage from "./ui/pages/ProcessingTelegramPage";
import SandboxPage from "./ui/pages/SandboxPage";
import StickerpackPage from "./ui/pages/StickerpackPage";
import routes from "./routes";

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add("app-locale--" + window.clientConfig.locale);
    document.body.classList.add("app-lang--" + window.clientConfig.lang);
    document.body.classList.add("app-type--" + (window.clientConfig.isWebview ? "webview" : "web"));

    if (window.clientConfig.isWebviewAndroid) {
      document.body.classList.add("app-webview--android");
    } else if (window.clientConfig.isWebviewIOS) {
      document.body.classList.add("app-webview--ios");
    }
  }

  render() {
    return <React.Fragment>
      <Switch>
        <Route exact path={routes.INDEX} render={props => <IndexPage {...props} />} />,
        <Route exact path={routes.UPLOAD} render={props => <UploadPage {...props} />} />,
        <Route exact path={routes.PROCESSING} render={props => <ProcessingPage {...props} />} />,
        <Route exact path={routes.PROCESSING_TELEGRAM} render={props => <ProcessingTelegramPage {...props} />} />,
        <Route path={routes.STICKERPACKS} render={props => <StickerpackPage key={props.match.params.hash} {...props} />} />,
        <Route exact path={routes.SANDBOX} render={props => <SandboxPage {...props} />} />,
      </Switch>
      <ModalWrapper />
      <NativeAdOverlay />
    </React.Fragment>;
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);