import i18n from "i18next";
import backend from "i18next-xhr-backend";

import enStrings from "./languages/en";

const resources = {
  en: enStrings,
};

const locales = Object.keys(resources);

i18n.use(backend).init({
  resources: resources,
  lng: window.clientConfig.locale,
  fallbackLng: "en",
  debug: window.appConfig.isDebug,
  ns: ["strings"],
  defaultNS: "strings",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
}, () => {
  window.axios.defaults.headers.common["X-Locale"] = i18n.language;
});

export default i18n;

export function getCurrentLocale() {
  return i18n.languages.find((lng) => locales.indexOf(lng) !== -1);
}
