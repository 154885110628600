import React from "react";
import AppContext from "../../contexts/AppContext";
import {getLocationQueryObject} from "../../utils/text";
import Loader from "./Loader";
import i18n from "../../i18n";

export default class NativeAdOverlay extends React.Component {

  componentDidMount() {
    const query = getLocationQueryObject();
    if (query["nad_showed"] === "1") {
      this.handleAdShown(query);
    }

    window.webviewHolders.nativeAdShown.subscribe((data) => {
      if (data != null) {
        if (window.clientConfig.isWebviewIOS) {
          data = JSON.parse(decodeURIComponent(data));
        }

        this.handleAdShown(data);
      }
    }, true);
  }

  handleAdShown = (data) => {
    console.log("nativeAd: " + JSON.stringify(data));

    const r = window.clientConfig.isWebviewAndroid
      ? window.devicePixelRatio
      : 1;

    this.context.setNativeAdState(
      parseInt(data["nad_showed"]) === 1,
      parseInt(data["nad_x"]) / r,
      parseInt(data["nad_y"]) / r,
      parseInt(data["nad_width"])  / r,
      parseInt(data["nad_height"])  / r,
    );
  };

  render() {
    return <section className="native-ad-overlay" hidden={!this.context.nativeAd.isShown}>
      <div className="loader-positioner" style={{height: this.context.nativeAd.y}}>
        <Loader message={i18n.t("loader_processing")} />
      </div>
    </section>;
  }
}

NativeAdOverlay.contextType = AppContext;