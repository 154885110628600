import Creative from "../photolab/Creative";

export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = `Code: ${data.error_code}, Message: ${data.error_message}`;
    this.response = data;
  }
}

function checkApiResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

// eslint-disable-next-line no-unused-vars
function paramsToStr(params = {}, random = true) {
  if (random) {
    params.r = Math.random();
  }

  return Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
}

function logEvent(eventId, eventParams, userParams, webviewParams) {
  return window.axios.post(window.appConfig.analytics.endpoint, {
    id: eventId,
    params: eventParams,
    user_params: userParams,
    webview_params: webviewParams,
  }).then(checkApiResponse);
}

export function photolabSign(data) {
  return window.axios.post(window.appConfig.paths.apiSign + "/photolab/sign", {data})
      .then(checkApiResponse);
}

export function createFile(file, data, params) {
  params = params || {};

  const formData = new FormData();

  if (file instanceof File) {
    formData.append("file", file);
  } else {
    formData.append("image_url", file);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  return window.axios.post(window.appConfig.paths.apiUpload + "/files/create", formData, {
    headers: {"Content-Type": "multipart/form-data"}
  }).then(checkApiResponse);
}

export function fetchFile(id, params = {}) {
  return window.axios.get(window.appConfig.paths.api + "/files/" + id + "?" + paramsToStr(params))
      .then(checkApiResponse);
}

export function createFileByTelegramFileId(id) {
  return window.axios.post(window.appConfig.paths.api + "/files/create/telegram", {id})
      .then(checkApiResponse);
}

export function fetchTask(taskId) {
  return window.axios.get(window.appConfig.paths.api + "/tasks/" + taskId)
      .then(checkApiResponse);
}

export function enqueueStickerpackExportTelegram(stickerpackId) {
  return createTask("stickerpack_export_telegram", {
    stickerpack_id: stickerpackId,
  });
}

export function enqueueCreativeStoreTask(contentUrl, processing, creative) {
  return createTask("creative_store", {
    content_url: contentUrl,
    creative_template_id: creative.templateId,
    creative_original_file_id: creative.file.id,
    creative_head_template_id: creative.getExtra(Creative.EXTRA_HEAD_TEMPLATE_ID),
    stickerpack_id: processing.stickerpack.id,
    stickerpack_original_file_id: processing.stickerpack.file.id,
    stickerpack_head_template_id: processing.stickerpack.headTemplateId,
    stickerpack_gender: processing.stickerpack.gender,
  });
}

export function createTask(type, params) {
  return window.axios.post(window.appConfig.paths.api + "/tasks/create", {type, params})
      .then(checkApiResponse);
}

export function fetchStickerpacks(params) {
  const url = window.appConfig.paths.api + "/stickerpacks/list"
    + (params ? ("?" + paramsToStr(params)) : "");

  return window.axios.get(url)
      .then(checkApiResponse);
}

export function fetchStickerpack(hash) {
  const url = window.appConfig.paths.api + "/stickerpacks/" + hash;

  return window.axios.get(url)
      .then(checkApiResponse);
}

export function cloneStickerpack(hash) {
  return window.axios.post(window.appConfig.paths.api + "/stickerpacks/clone", {hash})
      .then(checkApiResponse);
}

export function deleteSticker(id) {
  return window.axios.post(window.appConfig.paths.api + "/stickerpacks/delete/sticker", {id})
      .then(checkApiResponse);
}

export function attachHeadPreview(result, params) {
  return window.axios.post(window.appConfig.paths.api + "/stickerpacks/attach/headpreview", {result, ...params})
      .then(checkApiResponse);
}

export default {
  logEvent,
  photolabSign,
};
