import PhotolabTaskBuilder from "./PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "./PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "./PhotolabTaskImageUrl";
import {PhotolabResponseParseError, photolabAddTask, PhotolabResponseError, photolabWaitTask} from "./api";
import Creative from "./Creative";
import * as api from "../utils/api";

export const handlersNames = {
  TEMPLATE: "template",
  SIMPLE: "simple",
  COMBO: "combo",
};

export const handlersMap = {
  [handlersNames.TEMPLATE]: templateHandler,
  [handlersNames.SIMPLE]: simpleHandler,
  [handlersNames.COMBO]: comboHandler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}

class ApiResponseError extends Error {
  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = `Code: ${data.error_code}, Message: ${data.error_message}`;
    this.response = data;
  }
}

class ApiTaskError extends Error {
  constructor(task) {
    super();
    this.name = "ApiTaskError";
    this.code = -1;
    this.message = task.result && task.result.reason;
  }
}

class HandlerCancelError extends Error {
  constructor(message) {
    super();
    this.name = "HandlerCancelError";
    this.code = -1;
    this.message = message;
  }
}

function defaultHandlerCatch(creative, reject) {
  return (err) => {
    const type = "internal";
    const errorState = {
      type,
      name: err.name,
      code: err.code,
      message: err.message,
    };

    if (err instanceof PhotolabResponseError || err instanceof PhotolabResponseParseError) {
      errorState.type = "photolab";
    } else if (err instanceof ApiResponseError) {
      errorState.type = "api";
    } else if (err instanceof ApiTaskError) {
      errorState.type = "api_task";
    } else if (err instanceof HandlerCancelError) {
      errorState.type = "handler_cancel";
    }

    creative.markAsFailed(errorState);

    reject(creative);
  };
}

function waitHead(processing, templateId, fileId) {
  return waitCreative(processing.creatives.find((c) =>
      c.templateId === templateId
      && c.alias === "head"
      && c.file.id === fileId
  ));
}

function waitCreative(creative) {
  return new Promise((resolve) => {
    if (creative.isProcessed || creative.isFailed) {
      resolve(creative);
    } else {
      setTimeout(() => {
        waitCreative(creative).then(resolve);
      }, 250);
    }
  });
}

function waitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0) {
  function _call(resolve, reject) {
    api.fetchTask(taskId).then((task) => {
      if (task.status === 1) {
        resolve(task);
      } else if (task.status === -1) {
        throw new ApiTaskError(task);
      } else {
        setTimeout(() => {
          waitTask(taskId, 0, interval).then(resolve).catch(reject);
        }, interval || 1000);
      }
    }).catch((error) => {
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    if (timeout === 0) {
      _call(resolve, reject);
    } else {
      setTimeout(() => _call(resolve, reject), timeout);
    }
  });
}

export function templateHandler(processing, creative) {
  function createTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(creative.file.url))
      .build();
  }

  return new Promise((resolve, reject) => {
    photolabAddTask(createTask())
      .then((taskResult) => photolabWaitTask(taskResult.requestId, 3000, 1000))
      .then((taskResult) => {
        creative.markAsProcessed(taskResult);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

export function simpleHandler(processing, creative) {
  function createTask(headCreative) {
    return new PhotolabTaskBuilder()
        .setLanguage(processing.language)
        .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
        .addImage(new PhotolabTaskImageUrl(headCreative.result.resultUrl))
        .build();
  }

  return new Promise((resolve, reject) => {
    waitHead(processing, creative.getExtra(Creative.EXTRA_HEAD_TEMPLATE_ID), creative.file.id)
      .then((headCreative) => photolabAddTask(createTask(headCreative)))
      .then((taskResult) => photolabWaitTask(taskResult.requestId, 3000, 1000))
      .then((taskResult) => api.enqueueCreativeStoreTask(taskResult.resultUrl, processing, creative))
      .then((taskResult) => waitTask(taskResult.id, 1000))
      .then((taskResult) => {
        creative.markAsProcessed(taskResult);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

export function comboHandler(processing, creative) {
  function createTask(templateId, imageUrl) {
    return new PhotolabTaskBuilder()
        .setLanguage(processing.language)
        .addMethod(new PhotolabTaskCollageMethod(templateId))
        .addImage(new PhotolabTaskImageUrl(imageUrl))
        .build();
  }

  function waitСhain(imageUrl, steps, stepIndex) {
    return new Promise((resolve, reject) => {
      photolabAddTask(createTask(steps[stepIndex], imageUrl))
        .then((taskResult) => photolabWaitTask(taskResult.requestId, 3000, 1000))
        .then((taskResult) => {
          const nextIndex = ++stepIndex;

          if (steps[nextIndex]) {
            waitСhain(taskResult.resultUrl, steps, nextIndex).then(resolve).catch(reject);
          } else {
            resolve(taskResult);
          }
        });
    });
  }

  return new Promise((resolve, reject) => {
    waitHead(processing, creative.getExtra(Creative.EXTRA_HEAD_TEMPLATE_ID), creative.file.id)
      .then((headCreative) => waitСhain(headCreative.result.resultUrl, creative.getExtra(Creative.EXTRA_COMBO_STEPS), 0))
      .then((taskResult) => api.enqueueCreativeStoreTask(taskResult.resultUrl, processing, creative))
      .then((taskResult) => waitTask(taskResult.id, 1000))
      .then((taskResult) => {
        creative.markAsProcessed(taskResult);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}
