module.exports = {
  "strings": {
    "index_page__select_photo_button": "Upload a photo",

    "button_choice_gender_male": "male",
    "button_choice_gender_female": "female",
    "button_choice_gender_text": "styles",
    "btn_retry": "retry",

    "step_count_text": "{{step_idx}} out of {{steps}}",
    "start_page_title": "Select the body you like most to proceed",
    "start_page_title_2": "Select the body you like most to proceed:",

    "loader_processing": "Processing",
    "loader_exporting": "Exporting",
    "loader_exporting_progress": "{{num}} of {{of}} exported",

    "error_modal__default_message": "Something went wrong. Try again.",
    "error_modal__network_message": "Please check your network connection.",

    "vector_tab_refresh__title": "Almost there!",
    "vector_tab_refresh__subtitle": "Choose head style",

    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",

    "footer_title": "Choose body style",

    "more_styles": "More styles",
  },
};
