import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";
import Loader from "../components/Loader";
import FileChooseButton from "../components/FileChooseButton";
import {logEvent, userEvents} from "../../utils/log";
import * as api from "../../utils/api";
import routes from "../../routes";
import {generatePath} from "react-router";
import ErrorView from "../components/ErrorView";

export default class IndexPage extends React.Component {
  state = {
    isLoading: false,
    error: null,
    stickerpacks: [],
  };

  componentDidMount() {
    api.fetchStickerpacks({limit: 2, is_owned: true})
      .then((result) => {
        this.setState({
          stickerpacks: result,
        })
      })
      .catch(console.error);
  }

  handleFileSelected = (file) => {
    this.props.history.replace({
      pathname: routes.UPLOAD,
      state: {file}
    });
  };

  handleStickerpackClick = (hash) => {
    this.props.history.push({
      pathname: generatePath(routes.STICKERPACKS, {hash}),
    });
  }

  render() {
    if (this.state.isLoading) {
      return <Loader
          message={i18n.t("loader_processing")} />;
    }

    if (this.state.error) {
      return <ErrorView
          error={this.state.error}
          onFileSelected={this.handleFileSelected} />;
    }

    return <IndexView
        stickerpacks={this.state.stickerpacks}
        onFileSelected={this.handleFileSelected}
        onStickerpackClick={this.handleStickerpackClick} />;
  }
}

IndexPage.contextType = AppContext;

function IndexView({stickerpacks, onFileSelected, onStickerpackClick}) {
  return <section className="index-page">
    <div className="container">
      <div className="sticker-packs">
        {stickerpacks.map((stickerpack) => <StickerpackView
          key={stickerpack.hash}
          stickerpack={stickerpack}
          onStickerpackClick={() => onStickerpackClick(stickerpack.hash)}
        />)}
      </div>
      <div className="btn-container">
        <FileChooseButton
          className="btn-upload"
          onFileSelected={onFileSelected}>
          <span dangerouslySetInnerHTML={{__html: i18n.t("index_page__select_photo_button")}} />
        </FileChooseButton>
      </div>
    </div>
  </section>;
}

function StickerpackView({stickerpack, onStickerpackClick}) {
  return <div className="sticker-pack-container sticker-pack-container-small" onClick={onStickerpackClick}>
    {stickerpack.stickers.map((sticker) => <div className="sticker-pack sticker-pack-small" style={{backgroundImage: `url(${sticker.result_file.url})`}} key={sticker.id} />)}
  </div>;
}