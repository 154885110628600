import Modal from "./Modal";
import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";
import {webviewShare} from "../../utils/webview";
import {SvgSprite} from "../components/SvgSprite";
import ChooseHeadModal from "./ChooseHeadModal";
import Creative from "../../photolab/Creative";
import {getHandlerByName, handlersNames} from "../../photolab/handlers";
import FileChooseButton from "../components/FileChooseButton";
import * as api from "../../utils/api";

const PROVIDER_WHATSAPP = 3;
const PROVIDER_FACEBOOK_MESSENGER = 5;
const PROVIDER_TELEGRAM = 6;
const PROVIDER_ALL = 9;

export default class StickerModal extends Modal {
  state = {
    headPreviewResult: [],
  };

  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  componentDidMount() {
    if (this.props.sticker.head_preview_result.length > 0) {
      this.setState({
        headPreviewResult: this.props.sticker.head_preview_result,
      });

      return;
    }

    if (this.props.sticker.original_file.id === this.props.processing.stickerpack.file.id) {
      this.setState({
        headPreviewResult: this.props.processing.stickerpack.headPreviewResult,
      });

      return;
    }

    const creative = new Creative()
      .setTemplateId(5444)
      .setFile(this.props.sticker.original_file)
      .setHandler(handlersNames.TEMPLATE)
      .setAlias("head_preview");

    const handler = getHandlerByName(creative.handler);
    handler(this.props.processing, creative)
      .then((creative) => {
        this.setState({
          headPreviewResult: creative.result.results,
        });

        this.props.onHeadPreviewUpdated(creative.result.results);
      })
      .catch(console.error);
  }

  handleShareClick = (provider) => {
    const params = {
      providers: `[${provider}]`,
      imageUrl: encodeURIComponent(this.props.imageUrl)
    };

    webviewShare(params);
  }

  handleDeleteClick = () => {
    this.dismiss();
    this.props.onDeleteClick();
  }

  handleHeadSelected = (template) => {
    if (template.templateId === parseInt(this.props.sticker.head_template_id)) {
      return;
    }

    this.dismiss();
    this.props.onHeadSelected(template);
  }

  handleFileSelected = (file) => {
    this.dismiss();
    this.props.onFileSelected(file);
  }

  handleChooseHeadClick = () => {
    const selectedTemplateId = parseInt(this.props.sticker.head_template_id);

    this.context.pushModal(<ChooseHeadModal
        className="choose-head-modal"
        key="StickerModal_ChooseHeadModal"
        result={this.state.headPreviewResult}
        selectedTemplateId={selectedTemplateId}
        onSelectTemplate={this.handleHeadSelected}
    />);
  }

  renderModal() {
    const selectedTemplateId = parseInt(this.props.sticker.head_template_id);

    const headPreviewResult = this.state.headPreviewResult
        .find((item) => item.templateId === selectedTemplateId);

    return <div className="modal-container">
      <div className="modal">
        <button className="btn-close" onClick={() => this.dismiss()}><SvgSprite viewBox="0 0 352 512" icon="icon-close" /></button>

        {headPreviewResult && this.props.isOwner && <button
            className="btn-choose-head"
            onClick={this.handleChooseHeadClick}>
          <img src={headPreviewResult.resultUrl} alt="preview"/>
          <span>+5</span>
        </button>}

        <div className="sticker">
          <img src={this.props.sticker.result_file.url} width={200} />
        </div>

        <div className="btns-container">
          <button
              hidden={!this.props.isOwner}
              className="btn-delete"
              onClick={this.handleDeleteClick}>
            Delete
          </button>

          <button
              className="btn-social"
              onClick={() => this.handleShareClick(PROVIDER_WHATSAPP)}
              hidden={!this.context.installedApps.whatsapp}>
            WhatsApp
          </button>
          <button
              className="btn-social"
              onClick={() => this.handleShareClick(PROVIDER_TELEGRAM)}
              hidden={!this.context.installedApps.telegram}>
            Telegram
          </button>
          <button
              className="btn-social"
              onClick={() => this.handleShareClick(PROVIDER_FACEBOOK_MESSENGER)}
              hidden={!this.context.installedApps.facebook}>
            Messenger
          </button>
          <button className="btn-social" onClick={() => this.handleShareClick(PROVIDER_ALL)}>
            Other
          </button>
          <FileChooseButton
              hidden={!this.props.isOwner}
              className="btn-upload"
              onFileSelected={this.handleFileSelected}>
            Upload
          </FileChooseButton>
        </div>
      </div>
    </div>
  }
}

StickerModal.contextType = AppContext;
