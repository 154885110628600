import React from "react";
import AppContext from "../../contexts/AppContext";
import i18n from "../../i18n";
import Loader from "../components/Loader";
import * as api from "../../utils/api";
import routes from "../../routes";
import config from "../../photolab/config";
import Creative from "../../photolab/Creative";
import {getHandlerByName, handlersNames} from "../../photolab/handlers";
import ChooseHeadModal from "../modals/ChooseHeadModal";
import Processing from "../../photolab/Processing";
import StickerModal from "../modals/StickerModal";
import ErrorView from "../components/ErrorView";
import {generatePath} from "react-router";

export default class StickerpackPage extends React.Component {

  state = {
    isLoading: true,
    isProcessing: true,
    isExporting: false,
    error: null,
    exportTask: null,
    processing: null,
    stickers: [],
    isOwner: false,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.processing && this.props.location.state.processing.creatives) {
      this.setState({
        processing: this.props.location.state.processing,
        isOwner: true,
      }, () => {
        this.checkProcessing();
      });
    } else {
      api.fetchStickerpack(this.props.match.params.hash)
        .then(this.fillProcessing)
        .catch((error) => {
          this.setState({
            isLoading: false,
            isProcessing: false,
            isExporting: false,
            error,
          });
      });
    }
  }

  componentWillUnmount() {}

  handleFileSelected = (file) => {
    this.props.history.replace({
      pathname: routes.UPLOAD,
      state: {file}
    });
  };

  fillProcessing = (result) => {
    const processing = new Processing();

    processing.setStickerpack({
      id: result.id,
      hash: result.hash,
      file: result.original_file,
      gender: result.gender,
      headTemplateId: parseInt(result.head_template_id),
      headPreviewResult: result.head_preview_result,
    });

    this.setState({
      processing,
      isLoading: false,
      isProcessing: false,
      stickers: result.stickers,
      isOwner: result.is_owner,
    }, () => {
      if (processing.stickerpack.headPreviewResult.length === 0) {
        this.handleHeadPreviewTask();
      }
    });
  }

  checkProcessing = () => {
    const processing = this.state.processing;

    const creatives = processing.creatives.filter((c) => c.alias === "creative");
    const processedCreatives = creatives.filter((c) => c.isProcessed);
    const finishedCreatives = creatives.filter((c) => c.isFinished);

    const stickers = processedCreatives.map((c) => c.result.result);

    this.setState({
      isLoading: processedCreatives.length < 1,
      isProcessing: creatives.length !== finishedCreatives.length,
      stickers: stickers,
    });

    if (finishedCreatives.length < creatives.length) {
      setTimeout(this.checkProcessing, 250);
    }
  };

  handleHeadPreviewTask = () => {
    const processing = this.state.processing;

    const creative = new Creative()
      .setTemplateId(5444)
      .setFile(processing.stickerpack.file)
      .setHandler(handlersNames.TEMPLATE)
      .setAlias("head_preview");

    processing.addCreative(creative);

    const handler = getHandlerByName(creative.handler);
    handler(processing, creative)
      .then((creative) => {
        processing.setStickerpack({
          ...processing.stickerpack,
          headPreviewResult: creative.result.results,
        });

        this.setState({processing});

        api.attachHeadPreview(processing.stickerpack.headPreviewResult, {
          "stickerpack_id": processing.stickerpack.id,
        });
      })
      .catch(console.error);
  }

  handleChooseHeadClick = () => {
    const processing = this.state.processing;

    this.context.pushModal(<ChooseHeadModal
      className="choose-head-modal"
      key="StickerpackPage_ChooseHeadModal"
      result={processing.stickerpack.headPreviewResult}
      selectedTemplateId={processing.stickerpack.headTemplateId}
      onSelectTemplate={this.handleSelectedHeadTemplate}
    />);
  };

  handleSelectedHeadTemplate = (template) => {
    const processing = this.state.processing;

    processing.setStickerpack({
      ...processing.stickerpack,
      headTemplateId: template.templateId,
    });

    this.setState({
      processing,
    }, this.handleHeadTask);
  };

  handleHeadTask = () => {
    const processing = this.state.processing;

    let creative = processing.creatives.find((c) => c.alias === "head"
      && c.templateId === processing.stickerpack.headTemplateId
      && c.file.id === processing.stickerpack.file.id
    );

    this.setState({
      isLoading: true,
    });

    if (!creative) {
      creative = new Creative()
        .setTemplateId(processing.stickerpack.headTemplateId)
        .setFile(processing.stickerpack.file)
        .setHandler(handlersNames.TEMPLATE)
        .setAlias("head");

      processing.addCreative(creative);

      this.setState({processing});

      const handler = getHandlerByName(creative.handler);

      handler(processing, creative)
        .then((creative) => {
          this.handleStickerTasks();
        })
        .catch((creative) => {
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.handleStickerTasks();
    }
  };

  handleStickerTasks = () => {
    const processing = this.state.processing;

    processing.creatives.map((c) => {
      if (c.alias === "creative") {
        processing.removeCreative(c);
      }
    });

    const templates = config.templates.filter((t) => this.state.stickers.find((sticker) => parseInt(sticker.template_id) === t.id));

    templates.map((t) => {
      const creative = new Creative()
        .setTemplateId(t.id)
        .setFile(processing.stickerpack.file)
        .setHandler(t.handler)
        .setAlias("creative")
        .setExtra(Creative.EXTRA_HEAD_TEMPLATE_ID, processing.stickerpack.headTemplateId)
        .setExtra(Creative.EXTRA_COMBO_STEPS, t.steps);

      processing.addCreative(creative);

      const handler = getHandlerByName(creative.handler);
      handler(processing, creative);
    });

    this.setState({processing}, this.checkProcessing);
  };

  handleStickerClick = (sticker) => {
    const processing = this.state.processing;

    this.context.pushModal(<StickerModal
      className="sticker-pack-page-modal"
      key="StickerpackPage_StickerModal"
      sticker={sticker}
      processing={processing}
      isOwner={this.state.isOwner}
      onDeleteClick={() => this.handleStickerDelete(sticker)}
      onHeadSelected={(template) => this.handleStickerUpdateHead(sticker, template)}
      onFileSelected={(file) => this.handleStickerUpdateFile(sticker, file)}
      onHeadPreviewUpdated={(result) => this.handleStickerUpdateHeadPreview(sticker, result)}
    />);
  };

  handleStickerUpdate = (sticker, headTemplateId, file) => {
    const templateConfig = config.templates.find((t) => parseInt(sticker.template_id) === t.id);

    const headCreative = new Creative()
      .setTemplateId(headTemplateId)
      .setFile(file)
      .setHandler(handlersNames.TEMPLATE)
      .setAlias("head");

    const stickerCreative = new Creative()
      .setTemplateId(templateConfig.id)
      .setFile(file)
      .setHandler(templateConfig.handler)
      .setAlias("creative")
      .setExtra(Creative.EXTRA_HEAD_TEMPLATE_ID, headTemplateId)
      .setExtra(Creative.EXTRA_COMBO_STEPS, templateConfig.steps);

    const processing = this.state.processing;
    processing.addCreative(headCreative);
    processing.addCreative(stickerCreative);

    const headHandler = getHandlerByName(headCreative.handler);
    headHandler(processing, headCreative);

    const stickerHandler = getHandlerByName(stickerCreative.handler);
    stickerHandler(processing, stickerCreative)
      .then((creative) => {
        const sticker = creative.result.result;

        const stickers = this.state.stickers;
        const index = stickers.findIndex(s => s.id === sticker.id);
        stickers[index] = sticker;

        this.setState({
          isLoading: false,
          stickers,
        });
      })
      .catch((creative) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  handleStickerUpdateHead = (sticker, template) => {
    this.setState({isLoading: true});

    this.handleStickerUpdate(sticker, template.templateId, sticker.original_file);
  };

  handleStickerUpdateFile = (sticker, file) => {
    this.setState({isLoading: true});

    api.createFile(file)
      .then((file) => this.handleStickerUpdate(sticker, sticker.head_template_id, file))
      .catch((err) => {
        this.setState({isLoading: false});
      });
  };

  handleStickerUpdateHeadPreview = (sticker, result) => {
    api.attachHeadPreview(result, {
      "sticker_id": sticker.id,
    });

    const stickers = this.state.stickers;
    const index = stickers.findIndex(s => s.id === sticker.id);
    stickers[index] = {...sticker, head_preview_result: result};

    this.setState({stickers});
  };

  handleStickerDelete = (sticker) => {
    api.deleteSticker(sticker.id)
      .then(() => {
        const stickers = this.state.stickers.filter((s) => s.id !== sticker.id);

        if (stickers.length === 0) {
          this.props.history.replace(routes.INDEX);
        } else {
          this.setState({stickers});
        }
      })
      .catch(console.error);
  };

  handleExportToTelegramClick = (e) => {
    this.setState({isExporting: true});

    api.enqueueStickerpackExportTelegram(this.props.match.params.id)
      .then(this.handleTelegramExportStatus);
  };

  handleTelegramExportStatus = (task) => {
    const nextState = {
      exportTask: task,
    };

    if (task.status === 0 || task.status === 2) {
      this.exportTaskTimer = clearTimeout(this.exportTaskTimer);
      this.exportTaskTimer = setTimeout(() => {
        api.fetchTask(task.id).then(this.handleTelegramExportStatus);
      }, 1000);
    }

    if (task.status === 1) {
      nextState.isExporting = false;
    }

    if (task.status === -1) {
      nextState.isExporting = false;
    }

    this.setState(nextState);
  };

  handleCloneClick = () => {
    this.setState({isLoading: true});

    api.cloneStickerpack(this.props.match.params.hash)
      .then((result) => {
        this.props.history.push({
          pathname: generatePath(routes.STICKERPACKS, {hash: result.hash}),
        });
      })
      .catch((err) => {
        this.setState({isLoading: false});
      });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader message={i18n.t("loader_processing")} />;
    }

    if (this.state.error) {
      return <ErrorView
          error={this.state.error}
          onFileSelected={this.handleFileSelected} />;
    }

    if (this.state.isExporting) {
      let text = i18n.t("loader_exporting");

      const step = this.state.exportTask
        && this.state.exportTask.result
        && this.state.exportTask.result["step"];

      if (step === "export_stickers") {
        text = i18n.t("loader_exporting_progress", {
          num: this.state.exportTask.result["progress"],
          of: this.state.exportTask.result["of"],
        });
      }

      return <Loader message={text} />;
    }

    const processing = this.state.processing;
    const headPreviewResult = processing.stickerpack.headPreviewResult
        .find((item) => item.templateId === processing.stickerpack.headTemplateId);

    return <section className="sticker-pack-page">
      <div className="container">
        {headPreviewResult && this.state.isOwner && <button
          className="btn-choose-head"
          onClick={this.handleChooseHeadClick}>
          <img src={headPreviewResult.resultUrl} alt="preview"/>
          <span>+5</span>
        </button>}

        {this.state.isProcessing && <div>PROCESSING...</div>}

        <div className="sticker-pack-container sticker-pack-container-large">
          {this.state.stickers.map((sticker) => <div
            key={sticker.id}
            className="sticker-pack sticker-pack-large"
            style={{backgroundImage: `url(${sticker.result_file.url})`}}
            onClick={() => this.handleStickerClick(sticker)} />
          )}
        </div>

        {!this.state.isProcessing && this.state.isOwner && <div>
          <button onClick={this.handleExportToTelegramClick} children={"export to telegram"} />
        </div>}

        {!this.state.isOwner && <div>
          <button onClick={this.handleCloneClick} children={"clone"} />
        </div>}
      </div>
    </section>;
  }
}

StickerpackPage.contextType = AppContext;
