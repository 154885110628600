import { fabric } from "fabric";
import Hammer from "hammerjs";

fabric.AppCanvas = fabric.util.createClass(fabric.Canvas, {

  zoomStep: 0.1,
  minZoom: 1,
  maxZoom: 3,
  isCanvasDragging: false,
  canvasDragLastX: 0,
  canvasDragLastY: 0,

  _onMouseDown: function(e) {
    if (e.type === "touchstart") {
      return;
    }

    this.callSuper("_onMouseDown", e);

    const object = this.getActiveObject();
    if (!object) {
      const isTouch = e.touches && e.touches.length > 0;
      this.isCanvasDragging = true;
      this.canvasDragLastX = isTouch ? e.touches[0].clientX : e.clientX;
      this.canvasDragLastY = isTouch ? e.touches[0].clientY : e.clientY;
    }
  },

  _onMouseUp: function(e) {
    if (e.type === "touchend") {
      return;
    }

    this.callSuper("_onMouseUp", e);
    this.isCanvasDragging = false;
  },

  _onMouseMove: function(e) {
    // блокируем только в режиме перещения по канвасу
    if (e.type === "touchmove" && (this.isCanvasDragging || this.isObjectRotate)) {
      return;
    }

    const object = this.getActiveObject();
    if (object) {
      this.getActiveObject() && e.preventDefault && e.preventDefault();
      this.__onMouseMove(e);
      return;
    }

    if (this.isCanvasDragging) {
      const isTouch = e.touches && e.touches.length > 0;
      const vpt = this.viewportTransform;
      vpt[4] += (isTouch ? e.touches[0].clientX : e.clientX) - this.canvasDragLastX;
      vpt[5] += (isTouch ? e.touches[0].clientY : e.clientY) - this.canvasDragLastY;
      this.canvasDragLastX = e.clientX;
      this.canvasDragLastY = e.clientY;
      this.fixViewport();
    }
  },

  _onMouseWheel: function(e) {
    // this.callSuper("_onMouseWheel", e);
    const isChanged = this.updateZoom(e.deltaY > 0 ? -1 : 1, e.offsetX, e.offsetY);
    if (isChanged) {
      e.preventDefault();
      e.stopPropagation();
    }
  },

  addOrRemove: function(f, e) {
    this.callSuper("addOrRemove", f, e);

    const hammer = new Hammer(this.wrapperEl);
    hammer.get("pan").set({enable: true, threshold: 1});
    hammer.get("rotate").set({enable: true});
    hammer.get("pinch").set({enable: true});
    hammer.get("swipe").set({enable: false});
    hammer.get("press").set({enable: false});
    hammer.get("tap").set({enable: false});

    hammer.on("pinchstart", (e) => {
      this.initialPinchScale = this.getActiveObject() ? this.getActiveObject().scaleX : 1;
    });

    hammer.on("pinch", (e) => {
      const object = this.getActiveObject();
      if (object) {
        object.scale(e.scale * this.initialPinchScale);
        this.requestRenderAll();
      } else {
        this.updateZoom((e.scale - 1) * 0.5, e.center.x, e.center.y);
      }
    });

    hammer.on("rotatestart", (e) => {
      this.isObjectRotate = true;
      this.initialRotateTheta = e.rotation - (this.getActiveObject() ? this.getActiveObject().angle : 0);
      this.initialRotateAngle = this.getActiveObject() ? this.getActiveObject().angle : 0;
    });

    hammer.on("rotate", (e) => {
      const object = this.getActiveObject();
      if (object && this.isObjectRotate) {
        object.rotate(e.rotation - this.initialRotateTheta);
        this.requestRenderAll();
      }
    });

    hammer.on("rotateend", (e) => {
      this.isObjectRotate = false;
    });

    hammer.on("panstart", (e) => {
      if (e.pointers.length > 1) {
        return;
      }

      const object = this.getActiveObject();
      if (!object) {
        this.isCanvasDragging = true;
        this.canvasDragLastX = e.center.x;
        this.canvasDragLastY = e.center.y;
      }
    });

    hammer.on("panmove", (e) => {
      if (e.pointers.length > 1) {
        return;
      }

      if (this.isCanvasDragging) {
        const vpt = this.viewportTransform;
        vpt[4] += e.center.x - this.canvasDragLastX;
        vpt[5] += e.center.y - this.canvasDragLastY;
        this.canvasDragLastX = e.center.x;
        this.canvasDragLastY = e.center.y;
        this.fixViewport();
      }
    });

    hammer.on("panend", (e) => {
      this.isCanvasDragging = false;
    })
  },

  updateZoom: function(delta, x, y) {
    const prevZoom = this.getZoom();
    let nextZoom = prevZoom + delta * this.zoomStep;
    nextZoom = Math.max(nextZoom, this.minZoom);
    nextZoom = Math.min(nextZoom, this.maxZoom);

    this.zoomToPoint({x, y}, nextZoom);
    this.fixViewport(nextZoom);

    return nextZoom !== prevZoom;
  },

  fixViewport: function(zoom) {
    zoom = zoom || this.getZoom();

    const viewport = this.viewportTransform.slice();
    const cw = this.getWidth();
    const cwm = cw - cw * zoom;
    const ch = this.getHeight();
    const chm = ch - ch * zoom;

    if (viewport[4] >= 0) {
      viewport[4] = 0;
    } else if (viewport[4] < cwm) {
      viewport[4] = cwm;
    }

    if (viewport[5] >= 0) {
      viewport[5] = 0;
    } else if (viewport[5] < chm) {
      viewport[5] = chm;
    }

    this.setViewportTransform(viewport);
    this.requestRenderAll();
  },

});
