import Modal from "./Modal";
import React from "react";
import AppContext from "../../contexts/AppContext";
import i18n from "../../i18n";
import Loader from "../components/Loader";

export default class ChooseHeadModal extends Modal {
  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  handleSelectTemplate = (template) => {
    this.dismiss();
    this.props.onSelectTemplate(template);
  }

  renderModal() {
    return <section className="start-page">
      <div className="start-page-header">
        <div className="container">
          <div className="step-container">
            <h2 dangerouslySetInnerHTML={{__html: i18n.t("vector_tab_refresh__subtitle")}}/>
          </div>
        </div>
      </div>
      <div className="collage-container container">
        <div className="choose-items">
          {this.props.result.map((item) => <div className="choose-item-container" key={item.templateId}>
            <div className={"choose-item" + (item.templateId === this.props.selectedTemplateId ? " active" : "")}>
              <img src={item.resultUrl}
                 alt={item.templateId}
                 onClick={() => this.handleSelectTemplate(item)} />
            </div>
          </div>)}
        </div>
      </div>
    </section>;
  }
}

ChooseHeadModal.contextType = AppContext;
