import React from "react";
import AppContext from "../../contexts/AppContext";
import i18n from "../../i18n";
import * as api from "../../utils/api";
import routes from "../../routes";
import Loader from "../components/Loader";
import {getHandlerByName, handlersNames} from "../../photolab/handlers";
import Processing from "../../photolab/Processing";
import Creative from "../../photolab/Creative";
import config from "../../photolab/config";
import {generatePath} from "react-router";
import ErrorView from "../components/ErrorView";

export default class ProcessingPage extends React.Component {
  state = {
    isLoading: true,
    error: null,
    processing: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.startProcessing(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {}

  handleFileSelected = (file) => {
    this.props.history.replace({
      pathname: routes.UPLOAD,
      state: {file}
    });
  };

  startProcessing = (file) => {
    const processing = new Processing();
    processing.setStickerpack({
      ...processing.stickerpack,
      file,
    });

    this.setState({processing}, this.handleGenderTask);
  }

  handleGenderTask = () => {
    const processing = this.state.processing;

    const creative = new Creative()
      .setTemplateId("gender_classifier")
      .setFile(processing.stickerpack.file)
      .setHandler(handlersNames.TEMPLATE)
      .setAlias("gender");

    processing.addCreative(creative);

    const handler = getHandlerByName(creative.handler);
    handler(processing, creative)
      .then((creative) => {
        const gender = creative.result.gender.value;
        processing.setStickerpack({
          ...processing.stickerpack,
          gender,
          headTemplateId: gender === config.genders.male ? 5140 : 5135
        });

        this.setState({processing}, this.handleHeadTask);
      })
      .catch((creative) => {
        processing.setStickerpack({
          ...processing.stickerpack,
          gender: config.genders.male,
          headTemplateId: 5140,
        });

        this.setState({processing}, this.handleHeadTask);
      });
  }

  handleHeadTask = () => {
    const processing = this.state.processing;

    const creative = new Creative()
      .setTemplateId(processing.stickerpack.headTemplateId)
      .setFile(processing.stickerpack.file)
      .setHandler(handlersNames.TEMPLATE)
      .setAlias("head");

    processing.addCreative(creative);

    this.setState({processing});

    const handler = getHandlerByName(creative.handler);
    handler(processing, creative)
      .then((creative) => {
        this.handleHeadPreviewTask();
        this.handleStickerTasks();
      })
      .catch((creative) => {
        this.setState({
          isLoading: false,
          error: creative.error,
        });
      });
  }

  handleHeadPreviewTask = () => {
    const processing = this.state.processing;

    const creative = new Creative()
        .setTemplateId(5444)
        .setFile(processing.stickerpack.file)
        .setHandler(handlersNames.TEMPLATE)
        .setAlias("head_preview");

    processing.addCreative(creative);

    this.setState({processing});

    const handler = getHandlerByName(creative.handler);
    handler(processing, creative);
  }

  handleStickerTasks = () => {
    const processing = this.state.processing;
    const templates = config.templates;

    templates.map((t) => {
      const creative = new Creative()
        .setTemplateId(t.id)
        .setFile(processing.stickerpack.file)
        .setHandler(t.handler)
        .setAlias("creative")
        .setExtra(Creative.EXTRA_HEAD_TEMPLATE_ID, processing.stickerpack.headTemplateId)
        .setExtra(Creative.EXTRA_COMBO_STEPS, t.steps);

      processing.addCreative(creative);

      const handler = getHandlerByName(creative.handler);
      handler(processing, creative);
    });

    this.setState({processing}, this.checkProcessing);
  }

  checkProcessing = () => {
    const processing = this.state.processing;
    const templates = config.templates;

    const headPreviewTask = processing.creatives.find((c) => c.alias === "head_preview");
    const creatives = processing.creatives.filter((c) => c.alias === "creative");
    const processedCreatives = creatives.filter((c) => c.isProcessed);
    const failedCreatives = creatives.filter((c) => c.isFailed);

    if (failedCreatives.length === templates.length) {
      const firstFailedCreative = failedCreatives[0];

      this.setState({
        isLoading: false,
        error: firstFailedCreative.error,
      });

      return;
    }

    if (processedCreatives.length > 1 && headPreviewTask.isFinished) {
      const firstProcessedCreative = processedCreatives[0];
      const stickerpack = firstProcessedCreative.result.result.stickerpack;

      processing.setStickerpack({
        ...processing.stickerpack,
        id: stickerpack.id,
        hash: stickerpack.hash,
        headPreviewResult: headPreviewTask.isProcessed ? headPreviewTask.result.results : [],
      });

      if (processing.stickerpack.headPreviewResult.length > 0) {
        api.attachHeadPreview(processing.stickerpack.headPreviewResult, {
          "stickerpack_id": processing.stickerpack.id,
        });
      }

      this.props.history.replace({
        pathname: generatePath(routes.STICKERPACKS, {hash: stickerpack.hash}),
        state: {processing}
      });
    } else {
      setTimeout(this.checkProcessing, 250);
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loader
          message={i18n.t("loader_processing")}/>;
    }

    if (this.state.error) {
      return <ErrorView
          error={this.state.error}
          onFileSelected={this.handleFileSelected} />;
    }
  }
}

ProcessingPage.contextType = AppContext;
