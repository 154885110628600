import {handlersNames} from "./handlers";

const genders = {
  male: "male",
  female: "female",
};

const templates = [
  {id: 21038950, steps: [5467, 5492], handler: handlersNames.COMBO},
  {id: 21043279, steps: [4666, 5447], handler: handlersNames.COMBO},
  {id: 21043287, steps: [5448], handler: handlersNames.COMBO},
];

export default {
  genders,
  templates,
}

