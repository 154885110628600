export default class Processing {
  _stickerpack = {
    id: null,
    hash: null,
    file: null,
    headTemplateId: null,
    headPreviewResult: [],
    gender: null,
  };
  _creatives = [];
  _language = "en";

  get stickerpack() {
    return this._stickerpack;
  }

  get creatives() {
    return this._creatives;
  }

  setStickerpack(stickerpack) {
    this._stickerpack = stickerpack;
  }

  addCreative(creative) {
    this._creatives.push(creative);
  }

  removeCreative(creative) {
    this._creatives = this._creatives.filter(c=> c.id !== creative.id);
  }
}