import React from "react";
import AppContext from "../../contexts/AppContext";
import i18n from "../../i18n";
import * as api from "../../utils/api";
import routes from "../../routes";
import Loader from "../components/Loader";
import {generatePath} from "react-router";
import ErrorView from "../components/ErrorView";

export default class ProcessingTelegramPage extends React.Component {
  state = {
    isLoading: true,
    error: null,
    processing: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const fileId = (new URL(window.location.href)).searchParams.get("file_id");

    if (fileId) {
      this.createFile(fileId);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {}

  handleFileSelected = (file) => {
    this.props.history.replace({
      pathname: routes.UPLOAD,
      state: {file}
    });
  };

  createFile = (fileId) => {
    api.createFileByTelegramFileId(fileId)
      .then((result) => {
        if (result.stickerpack) {
          this.props.history.replace({
            pathname: generatePath(routes.STICKERPACKS, {hash: result.stickerpack.hash}),
          });

          return;
        }

        this.props.history.replace({
          pathname: routes.PROCESSING,
          state: {file: result.file}
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error,
        });
      });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader
          message={i18n.t("loader_processing")}/>;
    }

    if (this.state.error) {
      return <ErrorView
          error={this.state.error}
          onFileSelected={this.handleFileSelected} />;
    }
  }
}

ProcessingTelegramPage.contextType = AppContext;
