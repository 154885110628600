export function getImageDataPixel(imageData, x, y) {
  const offset = y * (imageData.width * 4) + (x * 4);

  return {
    x,
    y,
    r: imageData.data[offset],
    g: imageData.data[offset + 1],
    b: imageData.data[offset + 2],
    a: imageData.data[offset + 3],
  }
}

export function canvasContextRecognizeShape(ctx, width, height, peakThreshold = 0.05) {
  const shapeLeft = [];
  const shapeRight = [];
  const imageData = ctx.getImageData(0, 0, width, height);

  for (let y = 0; y < imageData.height; y++) {
    let leftX = -1;
    let rightX = imageData.width - 1;

    for (let x = 0; x < imageData.width; x++) {
      const pixel = getImageDataPixel(imageData, x, y);

      if (pixel.a > 0 && (pixel.r > 0 || pixel.g > 0 || pixel.b > 0)) {
        if (leftX === -1) {
          if (shapeLeft.length === 0) {
            leftX = x;
          } else {
            const dx = Math.abs(shapeLeft[shapeLeft.length - 1][0] - x);
            if (dx <= width * peakThreshold) {
              leftX = x;
            }
          }
        }

        rightX = x;
      }
    }

    if (leftX === -1) {
      continue;
    }

    shapeLeft.push([leftX, y]);
    shapeRight.push([rightX, y]);
  }

  return [
    ...shapeLeft,
    ...shapeRight.reverse(),
  ];
}

export function canvasContextRecognizeRect(ctx, width, height) {
  let minX = width-1;
  let maxX = 0;
  let minY = height-1;
  let maxY = 0;

  const imageData = ctx.getImageData(0, 0, width, height);

  for (let y = 0; y < imageData.height; y++) {
    for (let x = 0; x < imageData.width; x++) {
      const pixel = getImageDataPixel(imageData, x, y);

      if (pixel.a > 0) {
        maxX = Math.max(maxX, x);
        minX = Math.min(minX, x);
        maxY = Math.max(maxY, y);
        minY = Math.min(minY, y);
      }
    }
  }

  return [[minX, minY], [maxX, maxY]];
}

export function fabricObjectScaleToTarget(obj, target) {
  if (obj.width > target.width && obj.height > target.width) {
    if (target.width > target.height) {
      obj.scaleToHeight(target.height);
    } else {
      obj.scaleToWidth(target.width);
    }
  } else if (obj.width > target.width) {
    obj.scaleToWidth(target.width);
  } else if (obj.height > target.height) {
    obj.scaleToHeight(target.height);
  }
}

export function canvasContextRecognizeShape2(ctx, width, height) {
  const points = [];
  const startPoint = [Infinity, Infinity];
  const data = ctx.getImageData(0, 0, width, height);

  for (let y = data.height - 1; y >= 0; y--) {
    for (let x = 0; x < data.width; x++) {
      const pixel = getImageDataPixel(data, x, y);
      if (pixel.a > 0) {
        startPoint[0] = x;
        startPoint[1] = y;
        break;
      }
    }

    if (startPoint[0] !== Infinity) {
      break;
    }
  }

  points.push(startPoint.slice());

  const cp = startPoint.slice();
  const pp = [0, 0];

  let i = 0;
  do {
    if (i >= 100_000_000) {
      break;
    }

    i++;
  } while (cp[0] !== startPoint[0] && cp[1] !== startPoint[1]);

  console.log(i);

  return points;
}


// lookup tables for marching directions
var d3_geom_contourDx = [1, 0, 1, 1,-1, 0,-1, 1,0, 0,0,0,-1, 0,-1,NaN],
  d3_geom_contourDy = [0,-1, 0, 0, 0,-1, 0, 0,1,-1,1,1, 0,-1, 0,NaN];

export function contour(grid, start) {
  var s = start || d3_geom_contourStart(grid), // starting point
    c = [],    // contour polygon
    x = s[0],  // current x position
    y = s[1],  // current y position
    dx = 0,    // next x direction
    dy = 0,    // next y direction
    pdx = NaN, // previous x direction
    pdy = NaN, // previous y direction
    i = 0;

  do {
    // determine marching squares index
    i = 0;
    if (grid(x-1, y-1)) i += 1;
    if (grid(x,   y-1)) i += 2;
    if (grid(x-1, y  )) i += 4;
    if (grid(x,   y  )) i += 8;

    // determine next direction
    if (i === 6) {
      dx = pdy === -1 ? -1 : 1;
      dy = 0;
    } else if (i === 9) {
      dx = 0;
      dy = pdx === 1 ? -1 : 1;
    } else {
      dx = d3_geom_contourDx[i];
      dy = d3_geom_contourDy[i];
    }

    // update contour polygon
    if (dx != pdx && dy != pdy) {
      c.push([x, y]);
      pdx = dx;
      pdy = dy;
    }

    x += dx;
    y += dy;
  } while (s[0] != x || s[1] != y);

  return c;
};

function d3_geom_contourStart(grid) {
  var x = 0,
    y = 0;

  // search for a starting point; begin at origin
  // and proceed along outward-expanding diagonals
  while (true) {
    if (grid(x,y)) {
      return [x,y];
    }
    if (x === 0) {
      x = y + 1;
      y = 0;
    } else {
      x = x - 1;
      y = y + 1;
    }
  }
}