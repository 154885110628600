import React from "react";
import AppContext from "../../contexts/AppContext";
import { fabric } from "fabric";
import "../canvas/canvas.fabric";
import {
  canvasContextRecognizeShape,
  fabricObjectScaleToTarget
} from "../canvas/canvas.utils";

function randomHexColor() {
  return "#" + Math.round(Math.random() * 255*255*255).toString(16);
}

export default class SandboxPage extends React.Component {

  state = {
    imageURL: "./assets/sample.png",
    snapshot: null,
    text: "Random text\nmultiline 🤣",
  };

  constructor(props) {
    super(props);

    this.canvasRef = null;
    this.canvas = null;

    this.textGroup = null;
    this.backgroundGroup = null;
  }

  componentDidMount() {
    this.initCanvas();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.textGroup !== null) {
      if (this.state.text !== prevState.text) {
        this.textGroup.item(1).set("text", this.state.text);
        const newWidth = this.textGroup.item(1).get("width") + 25;
        const newHeight = this.textGroup.item(1).get("height") + 25;

        this.textGroup.item(0).set("width", newWidth);
        this.textGroup.item(0).set("height", newHeight);
        this.textGroup.set("width", newWidth);
        this.textGroup.set("height", newHeight);
        this.canvas.requestRenderAll();
      }
    }
  }

  initCanvas = () => {
    fabric.Object.prototype.centeredScaling = true;
    fabric.Object.prototype.centeredRotation = true;
    fabric.Object.prototype.borderScaleFactor = 4;
    fabric.Object.prototype.borderColor = "#d00";
    fabric.Object.prototype.cornerColor = "#d00";
    fabric.Object.prototype.cornerSize = 16;
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.lockScalingFlip = true;
    fabric.Object.prototype.minScaleLimit = 0.25;

    this.canvas = new fabric.AppCanvas(this.canvasRef);
    this.canvas.selection = false;
    this.canvas.perPixelTargetFind = true;

    this.initImage();
  };

  initImage = () => {
    fabric.Image.fromURL(this.state.imageURL, (image) => {
      const shadowCanvas = document.createElement("canvas");
      const shadowCtx = shadowCanvas.getContext("2d");
      const imageElement = image.getElement();

      shadowCanvas.width = imageElement.width;
      shadowCanvas.height = imageElement.height;
      shadowCtx.drawImage(imageElement, 0, 0);

      const shape = canvasContextRecognizeShape(shadowCtx, imageElement.width, imageElement.height)
        .map((point) => {
          return {
            x: point[0],
            y: point[1],
          };
        });

      const shapeRect = new fabric.Object({
        width: imageElement.width,
        height: imageElement.height,
      });

      const shapeObj = new fabric.Polygon(shape, {
        fill: "#fff",
        stroke: randomHexColor(),
        strokeWidth: 0,
        strokeLineCap: "round",
        strokeLineJoin: "round",
        originX: "center",
        originY: "center",
        shadow: {
          affectStroke: false,
          blur: 10,
          offsetY: 5,
          color: "rgba(0, 0, 0, .25)",
        }
      });

      this.backgroundGroup = new fabric.Group([shapeRect, shapeObj, image], {
        name: "background_group",
        // selectable: false,
        // evented: false,
      });

      fabricObjectScaleToTarget(this.backgroundGroup, this.canvas);

      // ставим после группировки, чтобы обводка не влияла на размер внутри группы
      shapeObj.set("strokeWidth", 25);

      this.canvas.add(this.backgroundGroup);

      this.addTextObject();
    });
  };

  addTextObject = () => {
    const text = new fabric.Text(this.state.text, {
      originX: "center",
      originY: "conter",
      fill: "white",
      fontWeight: "bold",
      textAlign: "center",
    });

    const rect = new fabric.Rect({
      originX: "center",
      originY: "conter",
      width: text.width + 25,
      height: text.height + 25,
      rx: 16,
      ry: 16,
      fill: "black",
      strokeWidth: 5,
      stroke: "#0f0",
      shadow: {
        offsetY: 3,
        blur: 10,
        color: "rgba(0, 0, 0, .25)",
      }
    });

    this.textGroup = new fabric.Group([rect, text], {
      name: "text_group_1",
      top: this.canvas.height/2,
      left: this.canvas.width/2,
      originX: "center",
      originY: "conter",
      centeredScaling: true,
      centeredRotation: true,
      padding: 10,
      angle: 15,
    });

    this.textGroup.setControlsVisibility({
      tl: false,
      tr: false,
      bl: false,
      br: window.clientConfig.isWebDesktop,
      mtr: window.clientConfig.isWebDesktop,
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });

    this.canvas.add(this.textGroup);

    this.textGroup.bringToFront();
  };

  handleMakeSnapshotButtonClick = () => {
    this.canvas.absolutePan(new fabric.Point(0, 0));
    this.canvas.setZoom(1);
    this.canvas.requestRenderAll();

    this.setState({
      snapshot: this.canvas.toDataURL({
        format: "png",
        multiplier: 1 + (1 - (this.canvasRef.width / 1080)),
        enableRetinaScaling: true,
      })
    });
  };

  handleChangeBackgroundStrokeSize = (e) => {
    if (e.target.value <= 0) {
      this.backgroundGroup.item(1).set("visible", false);
    } else {
      this.backgroundGroup.item(1).set("visible", true);
    }

    this.backgroundGroup.item(1).set("strokeWidth", e.target.value);
    this.canvas.requestRenderAll();
  };

  changeBackgroundStrokeColor = (color) => {
    this.backgroundGroup.item(1).set("stroke", color);
    this.canvas.requestRenderAll();
  };

  render() {
    const size = Math.min(document.body.offsetWidth, 640);

    return <div>
      <div className="canvas-sandbox">

        <canvas
          ref={(ref) => this.canvasRef = ref}
          width={size}
          height={size} />

        <div className="canvas-controls">
          <div>
            Stroke width
            &nbsp;
            <input
              type="range"
              min={0}
              max={50}
              step={5}
              onChange={this.handleChangeBackgroundStrokeSize}
              defaultValue={25} />
          </div>
          <div>
            Stroke color
            &nbsp;
            <button
              onClick={() => this.changeBackgroundStrokeColor("#ffffff")}
              children={"White"} />
            &nbsp;
            <button
              onClick={() => this.changeBackgroundStrokeColor(randomHexColor())}
              children={"Random"} />
          </div>

          <div>
            <textarea
              defaultValue={this.state.text}
              onChange={(e) => this.setState({text: e.target.value})} />
          </div>
        </div>
      </div>

      <div>
        <div>
          <button onClick={this.handleMakeSnapshotButtonClick} children={"make snapshot to 1080px"} />
        </div>

        {this.state.snapshot && <img src={this.state.snapshot} style={{border: "1px solid violet"}} />}
      </div>


    </div>;
  }
}

SandboxPage.contextType = AppContext;